import React from "react";
import { Link } from "gatsby";
import instagramIcon from '../images/instagram.svg'
import mailIcon from '../images/mail.svg'
import styled from "styled-components"
import { instagram, siteEmail, theme } from '../../config/website'

const MenuContainer = styled.div`
    width: 100%;
    z-index: 20;
    height: calc(100% - 80px);
    position: absolute;
    display: ${props => props.isVisible ? 'inline-block' : 'none'};
`

const MenuLinkNavigation = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;

    a:first-child {
        margin-top: -40px;
    }
`

const HeadingLink = styled(Link)`
    padding: 16px;
    line-height: 22px;
    font-size: 22px;
    cursor: pointer;
    text-decoration: none;
    color: ${theme.primaryColor};
    font-family: 'Edmondsans-Medium';

    &.active, :hover, :active {
        text-decoration: underline;
        color: inherit;
    }

    :visited {
        color: inherit;
    }

    :not(.active) {
        color: ${theme.secondaryColor};
        font-family: 'Edmondsans';
    }
`

const ContactLinkNavigation = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -56px;

    >::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
`

const ContactLink = styled.a`
    float: left;
    margin: 0 8px;
`

const ContactImage = styled.img`
    padding: 0px 8px;
    margin: 0px;
    vertical-align: middle;
    height: 28px;
    width: 28px;
`

const MobileMenu = ({ location, isVisible }) => {
    const inactiveClassName = ""
    const activeClassName = "active"
    const isAboutActive = location.pathname.includes("about")

    return (
        <MenuContainer isVisible={isVisible}>
            <MenuLinkNavigation>
                <HeadingLink to="/" className={isAboutActive ? inactiveClassName : activeClassName}>
                    Work
                </HeadingLink>
                <HeadingLink to="/about" className={isAboutActive ? activeClassName : inactiveClassName}>
                    About
                </HeadingLink>
            </MenuLinkNavigation>
            <ContactLinkNavigation>
                <ContactLink href={"mailto:" + siteEmail}>
                    <ContactImage src={mailIcon} />
                </ContactLink>
                <ContactLink href={instagram.profileURL} target="_blank">
                    <ContactImage src={instagramIcon} />
                </ContactLink>
            </ContactLinkNavigation>
        </MenuContainer>
    )
}

export default MobileMenu