module.exports = {
    pathPrefix: '/',
    siteTitle: 'EG Hughes',
    siteTitleAlt: 'EGHughes',
    siteUrl: 'https://eghughes.ca',
    siteLanguage: 'en',
    siteLogo: '/icons/icon-512x512.png',
    siteDescription: 'Portfolio for EG Hughes',
    siteEmail: 'eghughesart@gmail.com',
    instagram: {
      profileName: '@eghughesart',
      profileURL: 'https://www.instagram.com/eghughesart'
    },
    theme: {
      primaryColor: '#292929',
      secondaryColor: '#404040',
      manifest: {
        foregroundColor: '#292929',
        backgroundColor: '#404040'
      }
    }
  };