import React from "react";
import { Link } from "gatsby";
import closeIcon from '../images/close.svg'
import hamburgerIcon from '../images/hamburger.svg'
import instagramIcon from '../images/instagram.svg'
import mailIcon from '../images/mail.svg'
import styled from "styled-components"
import { instagram, siteEmail, theme } from '../../config/website'

const Container = styled.header`
    overflow: hidden;
    background-color: white;
`

const Heading = styled.h3`
    float: left;
    margin: 0;
    height: 24px;
    line-height: 24px;
`

const SiteHeading = styled.h2`
    float: left;
    margin: 0px;
    height: 24px;
    line-height: 30px;
`

const SiteHeadingLink = styled(Link)`
    font-family: 'Edmondsans';
    cursor: pointer;
    text-decoration: none;
    font-size: 32px;
    color: ${theme.secondaryColor};

    :hover, :active {
        color: black;
    }
`

const HeadingLink = styled(Link)`
    padding: 16px;
    cursor: pointer;
    font-size: 18px;
    text-decoration: none;
    color: ${theme.primaryColor};
    font-family: 'Edmondsans-Medium';

    :hover, :active {
        text-decoration: underline;
        color: inherit;
    }

    :visited {
        color: inherit;
    }

    :not(.active) {
        color: ${theme.secondaryColor};
        font-family: 'Edmondsans';
    }
`
const NavigationBar = styled.nav`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 767px) {
        display: none;
    }
`
const ContactBar = styled.nav`
    float: right;
    margin-right: -16px;

    >::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    @media (max-width: 767px) {
        display: none;
    }
`

const ContactLink = styled.a`
    float: left;
    margin: 0px 8px;
    height: 24px;
`

const ContactImage = styled.img`
    padding: 0px 8px;
    vertical-align: middle;
    width: 24px;
    height: 24px;
`

const MenuButton = styled.button`
    float: right;
    margin: 0;
    height: 24px;
    border: none;
    background: none;
    cursor: pointer;
    display: none;

    @media (max-width: 767px) {
        display: inline-block;
    }
`

const MenuIcon = styled.img`
    vertical-align: middle;
`

const Header = ({ location, isMenuVisible, onMenuClicked }) => {
    const inactiveClassName = ""
    const activeClassName = "active"
    const isAboutActive = location.pathname.includes("about")

    return (
        <Container>
            <SiteHeading>
                <SiteHeadingLink to="/">
                    EG HUGHES
                </SiteHeadingLink>
            </SiteHeading>
            <NavigationBar>
                <Heading>
                    <HeadingLink to="/" className={isAboutActive ? inactiveClassName : activeClassName}>
                        Work
                    </HeadingLink>
                </Heading>
                <Heading>
                    <HeadingLink to="/about" className={isAboutActive ? activeClassName : inactiveClassName}>
                        About
                    </HeadingLink>
                </Heading>
            </NavigationBar>
            <ContactBar>
                <ContactLink href={"mailto:" + siteEmail}>
                    <ContactImage src={mailIcon} />
                </ContactLink>
                <ContactLink href={instagram.profileURL} target="_blank">
                    <ContactImage src={instagramIcon} />
                </ContactLink>
            </ContactBar>
            <MenuButton onClick={onMenuClicked} >
                <MenuIcon src={isMenuVisible ? closeIcon : hamburgerIcon} />
            </MenuButton>
        </Container>
    )
}

export default Header