import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { theme } from '../../config/website'
import Header from "./header"
import MobileMenu from "./mobileMenu"
import classNames from "classnames"
import { StickyContainer, Sticky } from 'react-sticky'
import { globalHistory } from '@reach/router'

import EdmondsansRegular from '../fonts/Edmondsans-Regular.otf'
import EdmondsansMedium from '../fonts/Edmondsans-Medium.otf'

const Wrapper = styled.div`

  -webkit-tap-highlight-color: rgba(0,0,0,0);

  @font-face {
    font-family: 'Edmondsans';
    src: url('${EdmondsansRegular}') format('opentype');
  }

  @font-face {
    font-family: 'Edmondsans-Medium';
    src: url('${EdmondsansMedium}') format('opentype');
  }

  min-width: 320px;
  margin: 0 auto;
  font-family: "Helvetica";
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  font-smooth: never;
  -webkit-font-smoothing: antialiased;
  color: ${theme.primaryColor};

  .sticky {
    z-index: 10;

    &.sticky-active {
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.07);
    }
  }

  @media (min-width: 768px) {
      header {
        padding: 32px 56px;
      }
      main > div {
        padding: 0 56px 56px 56px;
      }
  }

}

  @media (max-width: 767px) {
    header {
        padding: 32px 24px;
    } 
    main > div {
        padding: 0 24px 24px 24px;
    }
  }

  h1 {
      font-size: 26px;
      font-weight: normal;
  }

  h2 {
      font-size: 24px;
      font-weight: normal;
  }

  h3 {
      font-size: 16px;
      font-weight: normal;
  }
`

const Content = styled.main`
    font-size: 16px;

    p, ul, li, pre {
        line-height: 24px;
        margin-bottom: 12px;
    }

    display: ${props => props.isMenuVisible ? 'none' : 'initial'};
`

const StickySpacer = styled.div`
    height: 1px;
`

const Layout = ({ children, location }) => {
    const [isMenuVisible, showMenu] = useState(false)

    // Detecting route change to hide menu on item click
    // Reference: https://github.com/reach/router/issues/262#issuecomment-501786634
    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH') {
                showMenu(false)
            }
        })
    })

    return (
        <Wrapper>
            <StickySpacer />
            <StickyContainer>
                <Sticky>
                    {({ style, isSticky }) => {
                        const className = classNames("sticky", {
                            "sticky-active": isSticky
                        })
                        return (
                            <div className={className} style={style}>
                                <Header 
                                    location={location} 
                                    isMenuVisible={isMenuVisible} 
                                    onMenuClicked={() => showMenu(!isMenuVisible)}
                                />
                            </div>
                        )
                    }}
                </Sticky>
                <MobileMenu isVisible={isMenuVisible} location={location} />
                <Content isMenuVisible={isMenuVisible}>{children}</Content>
            </StickyContainer>
        </Wrapper>
    )
};

export default Layout